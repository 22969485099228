

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/brush.webp";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { Opacity } from "@mui/icons-material";

function Counters() {
  const [colores, setColores] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);

  useEffect(() => {
    obtenerModelos();
    obtenerVersiones();
    obtenerColores();
    obtenerVehiculos();
  }, []);


  const obtenerModelos = () => {
    axios.get(`https://kodeapp.onrender.com/api/modelos`)
      .then(response => {
        setModelos(response.data);
      })
      .catch(error => {
        console.error('Error al obtener modelos:', error);
      });
  };

  const obtenerVersiones = async () => {

    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/versiones`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setVersiones(data || []);
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };

  const obtenerColores = async () => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/colores`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setColores(data.colores || []);
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };

  const obtenerVehiculos = async () => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/productos`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setVehiculos(data.productos || []);
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };



  return (
    <MKBox  mt={10} mb={5} p={2} sx={{ position: 'relative', width: '100%', py: 8,      overflow: 'hidden'
    }}>
  {/* MKBox con la imagen de fondo */}
  <MKBox 
    sx={{ 
      position: 'absolute', 
      top: 0, 
      left: 0, 
      height: '100%', 
      backgroundImage: `url(${bgImage})`, 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat', 
      backgroundPosition: 'center', 
      width: '100%', 
      zIndex: 0 ,
      transform: 'rotate(-4deg)',
      overflow: 'hidden',
      opacity: '30%',
    }} 
  />

  {/* MKBox con el contenido encima de la imagen de fondo */}
  <MKBox sx={{ position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center' }}>
    <MKBox sx={{ width: { xs: '100%', sm: '100%', lg: '100%' }, mx: 'auto' }}>
      <MKBox mb={0}>
        <MKTypography 
          sx={{ 
            fontFamily: 'atomic-regular', 
            letterSpacing: '0.15em', 
            color: '#FFD415', 
            transform: 'rotate(-2deg)',
            fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem', lg: '3rem', xl: '2.5rem' }
          }} 
          variant="h1" 
          align="center" 
          gutterBottom
        >
          Todas estas combinaciones a tu alcance
        </MKTypography>
      </MKBox>

      <MKBox mb={0} sx={{ display: 'flex', justifyContent: 'center' }}>
        <MKTypography 
          sx={{ 
            fontFamily: 'atomic-regular', 
            letterSpacing: '0.15em', 
            color: '#ffffff', 
            marginTop: '-10px', 
            transform: 'rotate(-2deg)',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2rem', xl: '1.5rem' } 
          }} 
          variant="h1" 
          align="center" 
          gutterBottom
        >
          prueba nuestro configurador 360°
        </MKTypography>
      </MKBox>

      <MKBox mb={5}>
        <MKBox component="section" pb={6}>
          <Container>
          <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={vehiculos.length}
                suffix="+"
                title="Vehículos"
                description="Explora la gama completa de vehículos disponibles, adaptados a tus necesidades y preferencias."
              />
            </Grid>
            <Grid item xs={12} md={3} display="flex">
              <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
              <DefaultCounterCard
                count={modelos.length}
                suffix="+"
                title="Modelos"
                description="Encuentra el modelo perfecto entre nuestra variedad de opciones diseñadas para cada estilo de vida."
              />
              <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={versiones.length}
                title="Versiones"
                description="Descubre todas las versiones disponibles para cada modelo, desde el equipamiento básico hasta las opciones premium."
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={colores.length}
                title="Colores"
                description="Personaliza tu vehículo eligiendo entre una amplia gama de colores únicos y atractivos."
              />
            </Grid>
          </Grid>
          </Container>
        </MKBox>
      </MKBox>
    </MKBox>
  </MKBox>
</MKBox>

  );
}

export default Counters;

