

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import './assets/fonts/fonts.css';  // Importa el archivo CSS de las fuentes
const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
);
