

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import icon_engine from "assets/images/icon-engine.png";
import icon_gear from "assets/images/icon-gear.png";
import icon_delivery from "assets/images/icon-delivery.png";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import MKBadge from "components/MKBadge";
import { Margin } from '@mui/icons-material';
import Button from "@mui/material/Button";
import { Box , Modal, Grid, IconButton, FormControlLabel, Checkbox,FormLabel,FormControl,FormGroup ,FormHelperText, Divider   } from "@mui/material";

import { ArrowBack, ArrowForward } from '@mui/icons-material';

const SelectorDinamico = ({ carSetSon }) => {
  const [modelos, setModelos] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [colores, setColores] = useState([]);
  const [equipamientos, setEquipamientos] = useState([]);
  const [modeloSeleccionado, setModeloSeleccionado] = useState([]);
  const [versionSeleccionada, setVersionSeleccionada] = useState([]);
  const [colorSeleccionado, setColorSeleccionado] = useState([]);
  const [equipamientoSeleccionado, setEquipamientoSeleccionado] = useState([]);
  const [motores, setMotores] = useState([]); // Ahora motores es un array
  const [medios, setMedios] = useState([]); // Ahora motores es un array
  const [mediosSeleccionado, setMediosSeleccionado] = useState([]); // Ahora motores es un array
  const [cambio, setCambio] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [cambioSeleccionado, setCambioSeleccionado] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [motorSeleccionado, setMotorSeleccionado] = useState([]); // Ahora cambio es un array para múltiples selecciones


  useEffect(() => {
    obtenerMedios();
    obtenerCambios();
    obtenerMotores();
    obtenerColores();
  }, []);

  useEffect(() => {
    obtenerColores(modeloSeleccionado);
  }, [modeloSeleccionado]);

  useEffect(() => {
    carSetSon(
      modeloSeleccionado, 
      versionSeleccionada,
      colorSeleccionado, 
      motorSeleccionado, 
      cambioSeleccionado, 
      mediosSeleccionado, 
    );
    //console.log('filtros', {motorSeleccionado, cambioSeleccionado, mediosSeleccionado, colorSeleccionado, modeloSeleccionado})
  }, [motorSeleccionado, versionSeleccionada, cambioSeleccionado, mediosSeleccionado, colorSeleccionado, modeloSeleccionado]);

  useEffect(() => {
    obtenerModelos(motorSeleccionado, cambioSeleccionado, mediosSeleccionado);
  }, [motorSeleccionado, cambioSeleccionado, mediosSeleccionado]);

  useEffect(() => {
    if (modeloSeleccionado) {
      obtenerVersiones(modeloSeleccionado, motorSeleccionado, cambioSeleccionado, mediosSeleccionado);
      if (versionSeleccionada) {
        obtenerColores(versionSeleccionada);
        
      } else {
        setColores([]);
      }
    } else {
      setVersiones([]);
    }
  }, [modeloSeleccionado, versionSeleccionada, colorSeleccionado, motorSeleccionado, cambioSeleccionado, mediosSeleccionado]);

  const obtenerMedios = async () => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/medios`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setMedios(data.medios || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };

  const obtenerCambios = async () => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/cambios`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setCambio(data.Cambios || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };

  const obtenerMotores = async () => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/motores`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setMotores(data.Motores || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };
  
  const obtenerModelos = (motorSeleccionado, cambioSeleccionado, mediosSeleccionado) => {
    const motoresQuery = motorSeleccionado.length > 0 ? encodeURIComponent(motorSeleccionado.join(',')) : '';
    const cambioQuery = cambioSeleccionado.length > 0 ? encodeURIComponent(cambioSeleccionado.join(',')) : '';
    const mediosQuery = mediosSeleccionado.length > 0 ? encodeURIComponent(mediosSeleccionado.join(',')) : '';


    //console.log(`https://kodeapp.onrender.com/api/modelos?motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`)

    axios.get(`https://kodeapp.onrender.com/api/modelos`)
      .then(response => {
        setModelos(response.data);
      })
      .catch(error => {
        console.error('Error al obtener modelos:', error);
      });
  };


  const obtenerVersiones = async (modeloId, motorSeleccionado, cambioSeleccionado, mediosSeleccionado) => {

    //console.log(`https://kodeapp.onrender.com/api/versiones?modelo_id=${modeloId}&motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`)
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/versiones?modelo_id=${modeloId}`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setVersiones(data || []);
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };
  

  const obtenerColores = async (modeloId) => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/colores?modelo_id=${modeloId}`);
      if (!response.ok) {
        throw new Error('No se pudieron cargar los detalles del producto');
      }
      const data = await response.json();
      setColores(data.colores || []);
    } catch (error) {
      console.error('Error al obtener los detalles del producto:', error);
    }
  };

  const handleMotoresChange = (e) => {
    const value = parseInt(e.target.value);
    setMotorSeleccionado(prevState =>
      prevState.includes(value)
        ? prevState.filter(c => c !== value) // Remover si ya está seleccionado
        : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleModeloChange = (e) => {
    const value = parseInt(e.target.value);
    setModeloSeleccionado(prevState =>
      prevState.includes(value)
        ? prevState.filter(c => c !== value) // Remover si ya está seleccionado
        : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleVersionChange = (e) => {
    const value = parseInt(e.target.value);
    setVersionSeleccionada(prevState =>
      prevState.includes(value)
        ? prevState.filter(c => c !== value) // Remover si ya está seleccionado
        : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleCambioChange = (e) => {
    const value = parseInt(e.target.value);
    setCambioSeleccionado(prevState =>
      prevState.includes(value)
        ? prevState.filter(c => c !== value) // Remover si ya está seleccionado
        : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleMediosImageClick = (medio) => {
    setMediosSeleccionado((prevState) =>
      prevState.includes(medio.id)
        ? prevState.filter((c) => c !== medio.id) // Remover si ya está seleccionado
        : [...prevState, medio.id] // Agregar si no está seleccionado
    );
  };

  const handleColoresImageClick = (color) => {
    setColorSeleccionado((prevState) =>
      prevState.includes(color.id)
        ? prevState.filter((c) => c !== color.id) // Remover si ya está seleccionado
        : [...prevState, color.id] // Agregar si no está seleccionado
        
    );
  };

  return (
    <Box sx={{width:'100%'}}>
<Grid container sx={{width: '100%'}}>
    <Grid item lg={12}>
      <FormControl sx={{ mt: 3, flex: '1 1 45%' }} component="fieldset" variant="standard">
      <FormLabel  component="legend" sx={{ 
            //color: 'rgba(255, 255, 255, 0.60)', 
            color: '#FFFFFF',
            fontSize: '1.1rem', 
            fontWeight: 'bold',
            '&.Mui-focused': {
              color: '#FFD415', // Color amarillo cuando está enfocado
            },
            '&.Mui-error': {
              color: '#FFD415', // Color amarillo cuando hay un error
            }
          }}>
        Motorizaciónes
      </FormLabel>
      <FormGroup sx={{marginTop:'10px'}}>
      {motores.map((m) => (
        <FormControlLabel
        control={
          <Checkbox
            checked={motorSeleccionado.includes(m.id)}
            onChange={handleMotoresChange}
            value={m.id}
            sx={{ color: 'rgba(255, 255, 255, 0.60)' }}
          />
        }
        label={m.nombre.charAt(0).toUpperCase() + m.nombre.slice(1)}
        sx={{
          color: 'rgba(255, 255, 255, 0.60)',
          '& .MuiTypography-root': {
            color: 'rgba(255, 255, 255, 0.60)',  // Asegura que el texto de la etiqueta sea blanco
            fontWeight: 'regular',
          }
        }}  
      />))}
      </FormGroup>
      <FormHelperText sx={{ color: 'rgba(255, 255, 255, 0.60)',fontSize: '0.8rem' ,marginTop:'10px'}}>
        Selecciona uno, mas de uno o ninguno
      </FormHelperText>
    </FormControl>
    </Grid>
    <Grid item lg={12}>
    <Divider sx={{backgroundColor:"#FFFFFF"}}/>
    <FormControl sx={{ mt: 3, flex: '1 1 45%' }} component="fieldset" variant="standard">
    <FormLabel component="legend" sx={{ //color: 'rgba(255, 255, 255, 0.60)', 
            color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 'bold',
      '&.Mui-focused': {
              color: '#FFD415', // Color amarillo cuando está enfocado
            },
            '&.Mui-error': {
              color: '#FFD415', // Color amarillo cuando hay un error
            }
     }}>
      Transmisiones
    </FormLabel>
    <FormGroup sx={{marginTop:'10px'}}>
      
      {cambio.map((c) => (
        <FormControlLabel
        control={
          <Checkbox
            checked={cambioSeleccionado.includes(c.id)}
            onChange={handleCambioChange}
            value={c.id}
            sx={{ color: 'rgba(255, 255, 255, 0.60)' }}
          />
        }
        label={c.nombre.charAt(0).toUpperCase() + c.nombre.slice(1)}
        sx={{
          color: 'rgba(255, 255, 255, 0.60)',
          '& .MuiTypography-root': {
            color: 'rgba(255, 255, 255, 0.60)',  // Asegura que el texto de la etiqueta sea blanco
            fontWeight: 'regular',
          }
        }}  
      />))}
    </FormGroup>
    <FormHelperText sx={{ color: 'rgba(255, 255, 255, 0.60)',fontSize: '0.8rem'  ,marginTop:'10px'}}>
    Selecciona uno, mas de uno o ninguno
    </FormHelperText>
  </FormControl>
    </Grid>
    <Grid item lg={12}>
    <Divider sx={{backgroundColor:"#FFFFFF"}}/>
    <FormControl component="fieldset" variant="standard" sx={{ mt: 3, flex: '1 1 45%' }}>
    <FormLabel component="legend" sx={{ //color: 'rgba(255, 255, 255, 0.60)', 
            color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 'bold',
      '&.Mui-focused': { color: '#FFD415' }, '&.Mui-error': { color: '#FFD415' } }}>
      Medios Ambientales
    </FormLabel>
    <FormGroup row sx={{ marginTop: '10px', justifyContent: 'space-around' }}>
      {medios.map((medio) => (
        <Box
        sx={{
          width: 50,
          height: 50,
          border: mediosSeleccionado.includes(medio.id) ? '3px solid #FFD415' : '3px solid transparent',
          cursor: 'pointer',
          borderRadius: '30px',
          overflow: 'hidden',
          boxShadow: mediosSeleccionado.includes(medio.id) ? '0px 0px 10px rgba(255, 212, 21, 0.7)' : 'none',
          marginBottom: '10px',
        }}
        onClick={() => handleMediosImageClick(medio)}
      >
        <img
          src={medio.imagen} 
          alt={medio.id}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
      ))}
    </FormGroup>
    <FormHelperText sx={{ color: 'rgba(255, 255, 255, 0.60)', fontSize: '0.8rem', marginTop: '10px' }}>
      Selecciona uno, más de uno o ninguno
    </FormHelperText>
  </FormControl>
    </Grid>
    <Grid item lg={12}>
    <Divider sx={{backgroundColor:"#FFFFFF"}}/>
    <FormControl sx={{ mt: 3, flex: '1 1 45%' }} component="fieldset" variant="standard">
    <FormLabel component="legend" sx={{ //color: 'rgba(255, 255, 255, 0.60)', 
            color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 'bold',
      '&.Mui-focused': {
              color: '#FFD415', // Color amarillo cuando está enfocado
            },
            '&.Mui-error': {
              color: '#FFD415', // Color amarillo cuando hay un error
            }
     }}>
      Modelos
    </FormLabel>
    <FormGroup sx={{marginTop:'10px'}}>
      {modelos.map((m) => (
        <FormControlLabel
        control={
          <Checkbox
            checked={modeloSeleccionado.includes(m.id)}
            onChange={handleModeloChange}
            value={m.id}
            sx={{ color: 'rgba(255, 255, 255, 0.60)' }}
          />
        }
        label={m.nombre.charAt(0).toUpperCase() + m.nombre.slice(1)}
        sx={{
          color: 'rgba(255, 255, 255, 0.60)',
          '& .MuiTypography-root': {
            color: 'rgba(255, 255, 255, 0.60)',  // Asegura que el texto de la etiqueta sea blanco
            fontWeight: 'regular',
          }
        }}  
      />))}
    </FormGroup>
    <FormHelperText sx={{ color: 'rgba(255, 255, 255, 0.60)',fontSize: '0.8rem'  ,marginTop:'10px'}}>
    Selecciona uno, mas de uno o ninguno
    </FormHelperText>
  </FormControl>
    </Grid>
    {modeloSeleccionado > 0 && (<Grid item lg={12}>
    <Divider sx={{backgroundColor:"#FFFFFF"}}/>
    <FormControl sx={{ mt: 3, flex: '1 1 45%' }} component="fieldset" variant="standard">
    <FormLabel component="legend" sx={{ //color: 'rgba(255, 255, 255, 0.60)', 
            color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 'bold',
      '&.Mui-focused': {
              color: '#FFD415', // Color amarillo cuando está enfocado
            },
            '&.Mui-error': {
              color: '#FFD415', // Color amarillo cuando hay un error
            }
     }}>
      Versiones
    </FormLabel>
    <FormGroup sx={{marginTop:'10px'}}>
      {versiones.map((v) => (
        <FormControlLabel
        control={
          <Checkbox
            checked={versionSeleccionada.includes(v.id)}
            onChange={handleVersionChange}
            value={v.id}
            sx={{ color: 'rgba(255, 255, 255, 0.60)' }}
          />
        }
        label={v.nombre.charAt(0).toUpperCase() + v.nombre.slice(1)}
        sx={{
          color: 'rgba(255, 255, 255, 0.60)',
          '& .MuiTypography-root': {
            color: 'rgba(255, 255, 255, 0.60)',  // Asegura que el texto de la etiqueta sea blanco
            fontWeight: 'regular',
          }
        }}  
      />))}
    </FormGroup>
    <FormHelperText sx={{ color: 'rgba(255, 255, 255, 0.60)',fontSize: '0.8rem'  ,marginTop:'10px'}}>
    Selecciona uno, mas de uno o ninguno
    </FormHelperText>
  </FormControl>
    </Grid>)}
    <Grid item lg={12}>
    <Divider sx={{backgroundColor:"#FFFFFF"}}/>
    <FormControl component="fieldset" variant="standard" sx={{ mt: 3, flex: '1 1 45%' }}>
    <FormLabel component="legend" sx={{ //color: 'rgba(255, 255, 255, 0.60)', 
            color: '#FFFFFF', fontSize: '1.2rem', fontWeight: 'bold',
      '&.Mui-focused': { color: '#FFD415' }, '&.Mui-error': { color: '#FFD415' } }}>
      Colores
    </FormLabel>
    <FormGroup row sx={{ marginTop: '10px', justifyContent: 'space-around' }}>
      {colores.map((color) => (
        <Box
        sx={{
          width: 50,
          height: 50,
          border: colorSeleccionado.includes(color.id) ? '3px solid #FFD415' : '3px solid transparent',
          cursor: 'pointer',
          borderRadius: '30px',
          overflow: 'hidden',
          boxShadow: colorSeleccionado.includes(color.id) ? '0px 0px 10px rgba(255, 212, 21, 0.7)' : 'none',
          marginBottom: '10px',
        }}
        onClick={() => handleColoresImageClick(color)}
      >
        <img
          src={color.img_color} 
          alt={color.id}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
      ))}
    </FormGroup>
    <FormHelperText sx={{ color: 'rgba(255, 255, 255, 0.60)', fontSize: '0.8rem', marginTop: '10px' }}>
      Selecciona uno, más de uno o ninguno
    </FormHelperText>
  </FormControl>
    </Grid>
  </Grid>
    </Box>



  );
};

export default SelectorDinamico;