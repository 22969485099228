import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Landing from "pages/Presentation/sections/landing";
import Presentation from "layouts/pages/presentation";
import ProductDetail from "pages/Presentation/sections/ProductDetail";
import Auto from "pages/KodeAuto"
import FullPageMessage from "pages/Presentation/sections/Testing";
import "./customScrollbar.css"; // Importa el archivo CSS aquí

import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Presentation/>} />

        <Route path="/testing" element={<Presentation />} />

        {getRoutes(routes)}
        <Route path="/productos/:id" element={<ProductDetail />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/autoescuela" element={<Auto/>} />

      </Routes>
    </ThemeProvider>
  );
}
