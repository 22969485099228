import React, { useState } from 'react';
import { Box, Grid, Card, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/system';
import etc from 'assets/images/etc.png';
import et0 from 'assets/images/et0.png';
import eteco from 'assets/images/eteco.png';
import MKTypography from "components/MKTypography";

const images = [
  { src: et0, alt: 'Etiqueta 0', text: 'Esta etiqueta se asigna a vehículos que no producen emisiones contaminantes. Incluye coches eléctricos de batería (BEV), coches eléctricos de autonomía extendida (REEV), vehículos eléctricos de pila de combustible (FCEV) y algunos híbridos enchufables con una autonomía eléctrica mínima de 40 km.' },
  { src: eteco, alt: 'Etiqueta ECO', text: 'Esta etiqueta se asigna a vehículos con bajas emisiones, pero no tan estrictamente limpios como los de la etiqueta 0. Incluye coches de gasolina y diésel que cumplen con los últimos estándares de emisiones Euro 6 (vehículos matriculados a partir de 2014 para los diésel y a partir de 2006 para los de gasolina), así como algunos híbridos no enchufables y vehículos de gas (GNC y GLP).' },
  { src: etc, alt: 'Etiqueta C', text: 'Esta etiqueta se asigna a vehículos que combinan tecnologías eficientes y de bajas emisiones. Incluye coches híbridos enchufables con una autonomía eléctrica inferior a 40 km, híbridos no enchufables, vehículos propulsados por gas natural comprimido (GNC) o gas licuado del petróleo (GLP), y algunos microhíbridos. Estos vehículos deben cumplir con los estándares de emisiones Euro 6 para los diésel y Euro 4 para los de gasolina.' },
];

const ImageCard = styled(Card)(({ theme, hoverEffect }) => ({
  position: 'relative',
  transition: 'transform 0.3s ease, opacity 0.3s ease',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  ...(hoverEffect && {
    transform: 'scale(0.9)',
    opacity: 0.5,
  }),
}));

const HoverText = styled(Typography)(({ theme, hoverEffect }) => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#181818',
  padding: '8px',
  color: 'white',
  textAlign: 'center',
  opacity: hoverEffect ? 1 : 0,
  transition: 'opacity 0.3s ease',
}));

const HoverEffectGrid = () => {
  const [hovered, setHovered] = useState(null);

  return (
    <Box  sx={{ width: '100%', padding: 4 }}>
      <Box sx={{ width: { xs: '100%', sm: '70%', lg: '70%' }, mx: 'auto' }}>
      <Box mb={5} lineHeight={1}>
        <Box mt={2} mb={1}>
          <MKTypography color="white" variant="h5" align="left" gutterBottom>
            DISTINTIVOS AMBIENTALES
          </MKTypography>
        </Box>
        <Box mb={1}>
          <MKTypography  sx={{ 
              color: '#ffffff', 
            }} 
            variant="body2" 
            align="left" 
            >
            En un esfuerzo por reducir las emisiones contaminantes y mejorar la calidad del aire, los vehículos en España se clasifican según el nivel de emisiones que producen. 
          </MKTypography>
          
        </Box>
        <Box mb={10}>
          <MKTypography  sx={{ 
              color: '#ffffff', 
            }} 
            variant="body2" 
            align="left" 
            >
              Este sistema de etiquetado medioambiental distingue a los coches en función de su impacto ecológico, asignándoles una etiqueta que les permite acceder a ciertos beneficios, como circular en zonas de bajas emisiones o disfrutar de ventajas fiscales. Conocer estos distintivos es esencial para tomar decisiones informadas y sostenibles a la hora de elegir un vehículo.          </MKTypography>
          
        </Box>
      </Box>
        <Grid container spacing={2}>
          {images.map((image, index) => (
            <Grid sx={{display: 'flex', justifyContent: 'center'}} item xs={12} sm={6} lg={4} key={index}>
              <ImageCard
                hoverEffect={hovered !== null && hovered !== index}
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
              >
                <CardMedia
                  component="img"
                  sx={{ height: '300px', width: '300px'}}
                  image={image.src}
                  alt={image.alt}
                />
                <HoverText sx={{ width: '100%'}} hoverEffect={hovered === index}>
                  <Typography sx={{ color: '#ffffff' }} variant="h5" mt={2} mb={1}>{image.alt}</Typography>
                  <Typography variant="body2" sx={{ color: '#ffffff' }}>{image.text}</Typography>
                </HoverText>
              </ImageCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HoverEffectGrid;
