import React from 'react';
import Box from '@mui/material/Box';
import MKTypography from "components/MKTypography";



const Comp247 = ({isOpenReservation}) => {
  
  const isMobileDevice = () => {
    return window.innerWidth <= 500; // Puedes ajustar este valor según tus necesidades
  };


  return (
    <>
      <Box
  sx={{

    position: 'absolute',
    top:isMobileDevice() ? '6%' : '4%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: '0.15s linear',
    width:isMobileDevice() ? '90%' : '80%', // Ajusta el ancho al 70% del contenedor padre
    borderRadius: '12px', // Ajusta el valor según el radio deseado
  }}
>
  <Box
    sx={{
      padding: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      backgroundColor: '#FFD415',
      borderRadius: 'inherit', // Hereda el radio de la caja padre
    }}
  >
    <MKTypography color="dark " variant="h6">
      Expertos independientes en Renault
    </MKTypography>
  </Box>
</Box>

      
    </>
  );
};

export default Comp247;

