import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import React, { useState, useEffect } from 'react';
import LogoSVG from 'assets/images/kode_logo.svg';
import LogoSVGScrolled  from 'assets/images/Capa 4kode.svg';

const pages = ['Vehículos', 'Preference', 'Financiación'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ onCambio, toggleDrawer }) {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
        onCambio(true);
      } else {
        setIsScrolled(false);
        onCambio(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onCambio]);

  return (
    <AppBar position="static" sx={{width:{
    xs: '90%', // tamaño de fuente para pantallas pequeñas
    sm: '90%', // tamaño de fuente para pantallas medianas
    md: '90%', // tamaño de fuente para pantallas grandes
    lg: '80%'},
    margin: 'auto', // Centra horizontalmente el AppBar
    borderRadius: '25px',
    backgroundColor: isScrolled ? 'info' : 'transparent', 
    backdropFilter: isScrolled ? 'saturate(200%) blur(30px)' : 'none', 
    transition: 'background-color 1.0s ease, backdrop-filter 1.0s ease',
    boxShadow: isScrolled ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none',

    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters
        sx={{
          justifyContent: 'space-between', // Centra los elementos dentro del Toolbar
          marginX: 'auto' // Agrega margen horizontal automático
      }}>
          

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="white"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography sx={{
                      color: isScrolled ? 'black' : '#ffffff',
                      textAlign: 'center',
                      transition: 'color 0.3s ease',
                    }} >{page} </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <img alt="Logo" src={LogoSVG/*isScrolled ? LogoSVGScrolled : LogoSVG*/}
              style={{ width: '120px', height: '60px', transition: 'all 0.3s ease' }}
            />

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
              color: '#ffffff',
              transition: 'color 0.3s ease',
            }}
          >
            
          </Typography>
          <Box sx={{alignItems:'center', textAlign:'center', justifyContent:'center', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={ () => {handleCloseNavMenu(); navigate('/autoescuela')} }
                sx={{ my: 2, color: '#ffffff',
                  transition: 'color 0.3s ease', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="?" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
