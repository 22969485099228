import * as React from 'react';
import {  Suspense, useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import MKBox from "components/MKBox";
import { Link as MuiLink } from '@mui/material';
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from 'pages/Presentation/sections/NavbarTest';
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/bg-renault.webp";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp"

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp"

import Accidente from "assets/images/Accidente.webp"
import Factura from "assets/images/Factura.webp"
import Tirada from "assets/images/Tirada.webp"

import CenteredFooter from "examples/Footers/CenteredFooter";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

function CookiesPolicy() {
  const navigate = useNavigate()

  const textsCards = [
    {
      title: '¿Nuevo... Usado?',
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Elegir entre un coche nuevo y uno usado depende de tus prioridades. Un coche nuevo ofrece garantía,
          tecnología avanzada y menor riesgo de fallos, aunque pueda parecer más caro al principio. Por otro lado, un coche usado 
          puede parecer más asequible inicialmente y tener una menor depreciación, pero puede tener problemas ocultos y menos tecnología, 
          comprometiendo la seguridad. Considera tu presupuesto, necesidades y disposición para lidiar con posibles reparaciones. 
          La elección ideal debe equilibrar el coste inicial, la fiabilidad y las características deseadas.</MKTypography>
        </>
      ),
      color: '#181818',
      image: Dudando,
    },
      {title:'Coche Nuevo',subtitle:(
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Pros:</strong> Garantía completa (cobertura total de reparaciones y mantenimiento),
           última tecnología (ADAS obligatorias), historial limpio (sin accidentes ni reparaciones previas), 
           financiación favorable (mejores tasas de interés).</MKTypography>
           <br/>
           <MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Contras:</strong> Depreciación rápida (pérdida significativa de valor en los primeros años),
             precio elevado (costo inicial alto).</MKTypography>
             <br/>
             <h3 style={{color:'#FFD415'}}>Coche Usado</h3>
             <br/>
             <MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Pros:</strong> Garantía completa (cobertura total de reparaciones y mantenimiento),
           última tecnología (ADAS obligatorias), historial limpio (sin accidentes ni reparaciones previas), 
           financiación favorable (mejores tasas de interés).</MKTypography>
           <br/>
           <MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Contras:</strong> Depreciación rápida (pérdida significativa de valor en los primeros años),
             precio elevado (costo inicial alto).</MKTypography>
        </>
      ), color:"#181818", image: PadreHijo},
    {title:'¡Descubre por qué el nuevo Renault Clio es imbatible!',subtitle:(
      <>
  <strong>Ventajas Inigualables:</strong><br/>
  <ul style={{paddingTop:'12px'}}>
  <li><MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Seguro a Todo Riesgo: </strong>Para conductores noveles sin coste adicional durante 3, 4 ó 5 años.</MKTypography></li>
  <li><MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Mantenimiento Incluido: </strong>Todos los servicios programados en la cuota mensual sin coste adicional durante 3, 4 ó 5 años .</MKTypography></li>
  <li><MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Costos Claros: </strong>El único gasto adicional es el impuesto municipal de circulación.</MKTypography></li>
  </ul>
  <br/>
  <strong>Sin Contras:</strong>
  <br/>
  <ul style={{paddingTop:'12px'}}>
  <li><MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Adquisición sin Entrada Inicial: </strong></MKTypography></li>
  <li><MKTypography variant="body2" sx={{color:'#FFFFFF'}}><strong>Depreciación Cubierta:</strong>Valor Mínimo Garantizado <strong>(VMG)</strong> al finalizar el periodo de 3, 4 o 5 años.</MKTypography></li>
  </ul>
  <br/>
  <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>¡No Esperes Más! Visítanos y te asesoraremos en la compra de tu nuevo Renault Clio hoy mismo.</MKTypography>

      </>
    ), color:"#181818", image: NewCar},
  ];

  const textsCards2 = [
    {
      title: 'Costes Ocultos',
      subtitle: (
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, los costes ocultos 
          de un coche de ocasión pueden ser un desafío abrumador. Reparaciones inesperadas, mantenimiento 
          y seguros pueden superar el presupuesto, causando estrés. Sin experiencia, es fácil pasar por alto 
          problemas ocultos que incrementen el coste total. Un coche nuevo es una opción inteligente: costes 
          controlados y garantías incluidas. Asegura una experiencia de conducción sin sorpresas desagradables. ¡Invierte en tranquilidad y seguridad al volante desde el primer día! 
        </MKTypography>
        </>
      ),
      color: '#181818',
      image: Factura,
    },
      {title:'Fiabilidad y Tranquilidad',subtitle:(
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, la fiabilidad y la 
          tranquilidad son esenciales al comprar un coche. La falta de experiencia dificulta evaluar un coche 
          de ocasión, llevando a averías frecuentes y altos costes de reparación. Sin garantías, la 
          incertidumbre y el estrés aumentan. Aunque un coche usado parezca económico, ya sabemos que lo 
          barato puede salir caro. Un coche nuevo ofrece todas las garantías y ninguna sorpresa desagradable. 
          Invierte en tranquilidad y seguridad al volante desde el primer día. ¡Elige sabiamente y conduce 
          sin preocupaciones! 
        </MKTypography>
        </>
      ), color:"#181818", image: Tirada},
    {title:'Seguridad Avanzada',subtitle:(
      <>
      <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, la fiabilidad y la 
        tranquilidad son esenciales al comprar un coche. La falta de experiencia dificulta evaluar un coche 
        de ocasión, llevando a averías frecuentes y altos costes de reparación. Sin garantías, la 
        incertidumbre y el estrés aumentan. Aunque un coche usado parezca económico, ya sabemos que lo barato 
        puede salir caro. Un coche nuevo ofrece todas las garantías y ninguna sorpresa desagradable. Invierte 
        en tranquilidad y seguridad al volante desde el primer día. ¡Elige sabiamente y conduce sin 
        preocupaciones! 
      </MKTypography>
      </>
    ), color:"#181818", image: Accidente},
  ];

  const textsCards3 = [
    {
      title: 'Historial',
      title2: 'Historial desconocido y Fraude',
      subtitle: (
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Los coches usados pueden tener un pasado oculto de accidentes o reparaciones 
          deficientes, lo que puede afectar su seguridad. Los conductores novatos son más susceptibles a caer en estafas como la manipulación 
          del odómetro o la venta de vehículos con problemas ocultos, incrementando el riesgo de adquirir un coche inseguro.
          <br/><br/>
          <strong>
          La historia de tu nuevo Renault Clio la inicias tu.
          </strong>

        </MKTypography>
        </>
      ),
      color: '#181818',
      image: Historial,
    },
      {title:'Kilometraje',
        title2: 'Kilometraje alto y Desgaste',
        subtitle:(
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Los coches usados suelen tener un kilometraje elevado, lo que indica un mayor 
          desgaste de sus componentes. Este desgaste puede llevar a la necesidad de costosas reparaciones y mantenimiento constante, 
          afectando tanto la seguridad como el presupuesto del conductor novato.<br/><br/>
          <strong>Estrena un Renault Clio nuevo: kilometraje a 
            cero y sin desgaste. 
          </strong>

        </MKTypography>
        </>
      ), color:"#181818", image: Kilometraje},
    {title:'Mantenimiento',
      title2: 'Inexperiencia en Mantenimiento y Revisiones deficientes',
      subtitle:(
      <>
      <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Los conductores novatos pueden no identificar problemas mecánicos a tiempo, 
        aumentando el riesgo de accidentes. La falta de experiencia en mantenimiento puede acelerar el deterioro del coche y aumentar los 
        costos de reparación, dificultando mantener el vehículo en buenas condiciones.<br/><br/>
        <strong>
        Tu Clio nuevo te avisa cuando necesita ir al taller. Con la app <q>My Renault</q>, siempre está monitorizado. 

        </strong>

      </MKTypography>
      </>
    ), color:"#181818", image: Mantenimiento},
  ];

  const textsCards4 = [
    {
      title: 'Costes Ocultos',
      title2: 'Inexperiencia en Mantenimiento y Revisiones deficientes',
      subtitle: (
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, los costes ocultos 
          de un coche de ocasión pueden ser un desafío abrumador. Reparaciones inesperadas, mantenimiento 
          y seguros pueden superar el presupuesto, causando estrés. Sin experiencia, es fácil pasar por alto 
          problemas ocultos que incrementen el coste total. Un coche nuevo es una opción inteligente: costes 
          controlados y garantías incluidas. Asegura una experiencia de conducción sin sorpresas desagradables. ¡Invierte en tranquilidad y seguridad al volante desde el primer día! 
        </MKTypography>
        </>
      ),
      color: '#181818',
      image: Factura,
    },
      {title:'Fiabilidad y Tranquilidad',
        title2: 'Inexperiencia en Mantenimiento y Revisiones deficientes',
        
        subtitle:(
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, la fiabilidad y la 
          tranquilidad son esenciales al comprar un coche. La falta de experiencia dificulta evaluar un coche 
          de ocasión, llevando a averías frecuentes y altos costes de reparación. Sin garantías, la 
          incertidumbre y el estrés aumentan. Aunque un coche usado parezca económico, ya sabemos que lo 
          barato puede salir caro. Un coche nuevo ofrece todas las garantías y ninguna sorpresa desagradable. 
          Invierte en tranquilidad y seguridad al volante desde el primer día. ¡Elige sabiamente y conduce 
          sin preocupaciones! 
        </MKTypography>
        </>
      ), color:"#181818", image: Tirada},
    {title:'Seguridad Avanzada',
      title2: 'Inexperiencia en Mantenimiento y Revisiones deficientes',
      
      subtitle:(
      <>
      <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, la fiabilidad y la 
        tranquilidad son esenciales al comprar un coche. La falta de experiencia dificulta evaluar un coche 
        de ocasión, llevando a averías frecuentes y altos costes de reparación. Sin garantías, la 
        incertidumbre y el estrés aumentan. Aunque un coche usado parezca económico, ya sabemos que lo barato 
        puede salir caro. Un coche nuevo ofrece todas las garantías y ninguna sorpresa desagradable. Invierte 
        en tranquilidad y seguridad al volante desde el primer día. ¡Elige sabiamente y conduce sin 
        preocupaciones! 
      </MKTypography>
      </>
    ), color:"#181818", image: Accidente},
  ];

  const textsCards5 = [
    {
      title: 'Costes Ocultos',
      subtitle: (
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, los costes ocultos 
          de un coche de ocasión pueden ser un desafío abrumador. Reparaciones inesperadas, mantenimiento 
          y seguros pueden superar el presupuesto, causando estrés. Sin experiencia, es fácil pasar por alto 
          problemas ocultos que incrementen el coste total. Un coche nuevo es una opción inteligente: costes 
          controlados y garantías incluidas. Asegura una experiencia de conducción sin sorpresas desagradables. ¡Invierte en tranquilidad y seguridad al volante desde el primer día! 
        </MKTypography>
        </>
      ),
      color: '#181818',
      image: Factura,
    },
      {title:'Fiabilidad y Tranquilidad',subtitle:(
        <>
        <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, la fiabilidad y la 
          tranquilidad son esenciales al comprar un coche. La falta de experiencia dificulta evaluar un coche 
          de ocasión, llevando a averías frecuentes y altos costes de reparación. Sin garantías, la 
          incertidumbre y el estrés aumentan. Aunque un coche usado parezca económico, ya sabemos que lo 
          barato puede salir caro. Un coche nuevo ofrece todas las garantías y ninguna sorpresa desagradable. 
          Invierte en tranquilidad y seguridad al volante desde el primer día. ¡Elige sabiamente y conduce 
          sin preocupaciones! 
        </MKTypography>
        </>
      ), color:"#181818", image: Tirada},
    {title:'Seguridad Avanzada',subtitle:(
      <>
      <MKTypography variant="body2" sx={{color:'#FFFFFF'}}>Para un conductor novel, la fiabilidad y la 
        tranquilidad son esenciales al comprar un coche. La falta de experiencia dificulta evaluar un coche 
        de ocasión, llevando a averías frecuentes y altos costes de reparación. Sin garantías, la 
        incertidumbre y el estrés aumentan. Aunque un coche usado parezca económico, ya sabemos que lo barato 
        puede salir caro. Un coche nuevo ofrece todas las garantías y ninguna sorpresa desagradable. Invierte 
        en tranquilidad y seguridad al volante desde el primer día. ¡Elige sabiamente y conduce sin 
        preocupaciones! 
      </MKTypography>
      </>
    ), color:"#181818", image: Accidente},
  ];

  const [valorHijo, setValorHijo] = useState('');
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    console.log(nuevoValor);
  };

  /*const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };*/

  
  return (
    <>
      <Box sx={{ backgroundColor: '#181818', minHeight: '100vh', }}>
        <Box
          ref={appBarRef}
          style={{
            position: 'sticky',
            top: valorHijo ? '15px' : '20px',
            transition: valorHijo ? 'top 1s ease-in' : 'top 0.0s ease-out',
            zIndex: 3
          }}
        >
          {<ResponsiveAppBar reservationDrawer={reservationDrawer} onCambio={manejarCambio} />}
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`
                  }
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <MKBox sx={{ maxWidth: '100%' }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: 'Rodina-Regular',
                        textAlign: 'center',
                        lineHeight: 1,
                        fontSize: {
                          xs: '2.5rem',
                          sm: '3rem',
                          md: '4rem',
                          lg: '5rem'
                        },
                        textShadow: '0px 2px 60px rgba(0, 0, 0, 0.999)'
                      }}
                    >
            After School
            </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: 'Rodina-Regular',
                        textAlign: 'center',
                        lineHeight: 1.5,
                        fontSize: {
                          xs: '1.5rem',
                          sm: '2rem',
                          md: '2rem',
                          lg: '2.5rem'
                        },
                        textShadow: '0px 2px 60px rgba(0, 0, 0, 0.999)'
                      }}
                    >
                      ¡Por fin! ¡Ya tienes el carnet!    Y ahora... ¡a por la libertad!


                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        {/* Cookie Policy Section */}
        <Container sx={{ mt: {
                        xs: -2,
                        sm: -2,
                        md: -2,
                        lg: -14,
                      }, mb: 8, backgroundColor: 'white' }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {textsCards.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: '#181818', boxShadow: 'none' }}>
      <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
        <div style={{ overflow: 'hidden', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
          <img
            src={text.image}
            alt={'fff'}
            style={{ maxHeight: '250px', width: '100%', borderRadius: '0', objectFit: 'cover' }}
          />
        </div>
      </MKBox>
      <MKBox p={2} mt={0} textAlign="center" alignItems="center">
        <MKBox mt={0} mb={3} mx={1} display="flex" alignItems="center" justifyContent="space-between">
          <MKBox flex={1} sx={{ textAlign: 'left' }}>
            <h2 display="inline" variant="h4" style={{ color: '#FFD415', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title}
            </h2>
            <br/>
            <MKBox >
            <MKTypography  sx={{ color: '#ffffff', textAlign: 'justify'}}>
            {text.subtitle}
            </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </MKBox>
    </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container sx={{ mt: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0,
                      }, mb: 8, backgroundColor: 'white' }}>


                        <Card sx={{backgroundColor:'rgba(255, 255, 255, 0.07)', 
                      padding: '20px',
                      width: '100%',
                      borderRadius: '8px',
                         }}>
                          <MKBox
                            sx={{
                              justifyContent: "center",
                              display: "flex",
                              position: "relative",
                              flexDirection: "column",
                            }}>
                            <MKTypography variant="h3"  sx={{ color: '#a8a8a8', textAlign:'center' }}>
                              Nuestro consejo
                            </MKTypography><MKTypography variant="h5"  sx={{ color: '#a8a8a8', textAlign:'center' }}>
                              Evita los riesgos de un coche de segunda mano.
                            </MKTypography>
                          </MKBox>
                        </Card>
                      </Container>
                      <Container sx={{ mt: {
                        xs: 10,
                        sm: 10,
                        md: 10,
                        lg: 10,
                      }, mb: 8, backgroundColor: 'white' }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {textsCards2.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: '#181818', boxShadow: 'none' }}>
      <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
        <div style={{ overflow: 'hidden', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
          <img
            src={text.image}
            alt={'fff'}
            style={{ maxHeight: '250px', width: '100%', borderRadius: '0', objectFit: 'cover' }}
          />
        </div>
      </MKBox>
      <MKBox p={2} mt={0} textAlign="center" alignItems="center">
        <MKBox mt={0} mb={3} mx={1} display="flex" alignItems="center" justifyContent="space-between">
          <MKBox flex={1} sx={{ textAlign: 'left' }}>
            <h2 display="inline" variant="h4" style={{ color: '#FFD415', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title}
            </h2>
            <br/>
            <MKBox >
            <MKTypography  sx={{ color: '#ffffff', textAlign: 'justify'}}>
            {text.subtitle}
            </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        
      </MKBox>
    </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        

        <Box
  sx={{
    minHeight: "99vh",
    width: "100%",
    backgroundImage: `url(${bgRenault})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: `-${appBarHeight}px`,
    position: "relative", // Necesario para el pseudo-elemento
    '::before': {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
    },
  }}
>
  {/* El contenido dentro de la caja */}
  <Box sx={{ 
  position: "relative", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: '100%',
}}>
  <Grid container sx={{ width: '100%' }} spacing={2}>
    <Grid item xs={12} lg={4}>
      
    </Grid>
    <Grid item xs={12} lg={4}>
      
    </Grid>
    <Grid item xs={12} lg={4} p={0} sx={{ overflow: 'hidden' }}>
      <Box sx={{ width: '100%' }}>
      <Grid container sx={{ width: '100%' }} spacing={2}>
        <Grid item xs={12} lg={12}>
        <MKTypography sx={{ 
             position: "relative", 
             display: "flex",
             justifyContent: "left",

            fontFamily: 'atomic-regular', 
            letterSpacing: '0.15em', 
            color: '#ffffff', 
            marginTop: '-10px', // Ajusta la separación superior según sea necesario
            transform: 'rotate(-2deg)',
            fontSize: { xs: '3.5rem', sm: '4rem', md: '6rem', lg: '8rem', xl: '2rem' },
            animation: 'blink-animation2 0.2s 3 1s', // Agrega la animación
            textShadow: '4px 2px 5px rgba(0, 0, 0, 0.05)',

          }} 
          variant="h1" 
          align="center" 
          gutterBottom
        >
          Renault
        </MKTypography>
        </Grid>
        <Grid item xs={12} lg={12}>
        <Box mt={-5}>
          <MKTypography sx={{ 
              fontFamily: 'atomic-regular', 
              letterSpacing: '0.15em', 
              color: '#FFD415', 
              marginTop: '-10px', // Ajusta la separación superior según sea necesario
              transform: 'rotate(-2deg)',
              fontSize: { xs: '3.5rem', sm: '4rem', md: '6rem', lg: '8rem', xl: '16rem' },
              animation: 'blink-animation2 0.2s 3 1s', // Agrega la animación
              textShadow: '4px 2px 5px rgba(0, 0, 0, 0.05)',


            }} 
            variant="h1" 
            align="center" 
            gutterBottom
          >
            Clio
          </MKTypography>
        </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box mt={-18}>
            <MKTypography sx={{ 
                fontFamily: 'atomic-regular', 
                letterSpacing: '0.15em', 
                color: '#FFFFFF', 
                marginTop: '-10px', // Ajusta la separación superior según sea necesario
                transform: 'rotate(-2deg)',
                fontSize: { xs: '3.5rem', sm: '4rem', md: '6rem', lg: '8rem', xl: '4rem' },
                animation: 'blink-animation2 0.2s 3 1s', // Agrega la animación
                textShadow: '4px 2px 5px rgba(0, 0, 0, 0.05)',


              }} 
              variant="h1" 
              align="center" 
              gutterBottom
            >
              189,00€ /mes
            </MKTypography>
        </Box>
        </Grid>
      </Grid>
      </Box>
    </Grid>
  </Grid>
</Box>

</Box>
<Container>
  <Box sx={{
            justifyContent: "center",
            display: "flex",
            position: "relative",
            flexDirection: "column",
          }}>
    <MKTypography variant="body2"  sx={{ color: '#FFFFFF', textAlign:'center' }}>
    Comprar un coche de segunda mano, especialmente uno de dudosa calidad, puede ser una 
    decisión arriesgada para un conductor novato. Aunque adquirir un coche usado puede parecer 
    económico, es esencial considerar los riesgos asociados con la seguridad y el mantenimiento. 
    Para los conductores noveles, invertir en un coche nuevo, como el Renault Clio, ofrece mayor 
    seguridad y fiabilidad, brindando tranquilidad y protección adicional en la carretera. 
    Principales riesgos que se deben considerar al comprar un vehículo usado para conductores novatos y 
    razones por las que no se recomienda esta práctica: 
    </MKTypography>
  </Box>
</Container>
<Container sx={{ mt: {
                        xs: 10,
                        sm: 10,
                        md: 10,
                        lg: 10,
                      }, mb: 8, backgroundColor: 'white' }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {textsCards3.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: '#181818', boxShadow: 'none' }}>
      <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
        <div style={{ overflow: 'hidden', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
          <img
            src={text.image}
            alt={'fff'}
            style={{ maxHeight: '250px', width: '100%', borderRadius: '0', objectFit: 'cover' }}
          />
        </div>
      </MKBox>
      <MKBox p={2} mt={0} textAlign="center" alignItems="center">
        <MKBox mt={0} mb={3} mx={1} display="flex" alignItems="center" justifyContent="space-between">
          <MKBox flex={1} sx={{ textAlign: 'left' }}>
            <h2 display="inline" variant="h4" style={{ color: '#FFD415', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title}
            </h2>
            <h4 display="inline"  style={{ color: '#FFFFFF', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title2}
            </h4>
            <br/>
            <MKBox >
            <MKTypography  sx={{ color: '#ffffff', textAlign: 'justify'}}>
            {text.subtitle}
            </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        
      </MKBox>
    </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container sx={{ mt: {
                        xs: 10,
                        sm: 10,
                        md: 10,
                        lg: 10,
                      }, mb: 8, backgroundColor: 'white' }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {textsCards4.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: '#181818', boxShadow: 'none' }}>
      <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
        <div style={{ overflow: 'hidden', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
          <img
            src={text.image}
            alt={'fff'}
            style={{ maxHeight: '250px', width: '100%', borderRadius: '0', objectFit: 'cover' }}
          />
        </div>
      </MKBox>
      <MKBox p={2} mt={0} textAlign="center" alignItems="center">
        <MKBox mt={0} mb={3} mx={1} display="flex" alignItems="center" justifyContent="space-between">
          <MKBox flex={1} sx={{ textAlign: 'left' }}>
            <h2 display="inline" variant="h4" style={{ color: '#FFD415', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title}
            </h2>
            <h5 display="inline" variant="h4" style={{ color: '#FFD415', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title2}
            </h5>
            <br/>
            <MKBox >
            <MKTypography  sx={{ color: '#ffffff', textAlign: 'justify'}}>
            {text.subtitle}
            </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        
      </MKBox>
    </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container sx={{ mt: {
                        xs: 10,
                        sm: 10,
                        md: 10,
                        lg: 10,
                      }, mb: 8, backgroundColor: 'white' }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {textsCards5.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: '#181818', boxShadow: 'none' }}>
      <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
        <div style={{ overflow: 'hidden', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
          <img
            src={text.image}
            alt={'fff'}
            style={{ maxHeight: '250px', width: '100%', borderRadius: '0', objectFit: 'cover' }}
          />
        </div>
      </MKBox>
      <MKBox p={2} mt={0} textAlign="center" alignItems="center">
        <MKBox mt={0} mb={3} mx={1} display="flex" alignItems="center" justifyContent="space-between">
          <MKBox flex={1} sx={{ textAlign: 'left' }}>
            <h2 display="inline" variant="h4" style={{ color: '#FFD415', textTransform: 'capitalize', fontWeight: 'bold' }}>
              {text.title}
            </h2>
            <br/>
            <MKBox >
            <MKTypography  sx={{ color: '#ffffff', textAlign: 'justify'}}>
            {text.subtitle}
            </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        
      </MKBox>
    </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        

        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />
              }
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default CookiesPolicy;