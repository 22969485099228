import * as React from 'react';
import { useState, useEffect } from 'react';

import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import RotatingCardProduct from "examples/Cards/RotatingCardProduct"
import RotatingCardFront from "examples/Cards/RotatingCardProduct/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCardProduct/RotatingCardBack";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import MKPagination from "components/MKPagination";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import VistaArbol from "pages/Presentation/sections/FiltrosDropdown";
import {Slider, Button, Select } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import CenteredBlogCardProduct from "examples/Cards/BlogCards/CenteredBlogCard Product";
import CenteredBlogCardProduct2 from "examples/Cards/BlogCards/CenteredBlogCard Product2";

function Productos({ carConfigR, carConfigR360 }) {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const [totalPages, setTotalPages] = useState(1);
  const [productos, setProductos] = useState([]);
  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (carConfigR) {
      const { modeloSeleccionado, versionSeleccionada, colorSeleccionado, motorSeleccionado, cambioSeleccionado, mediosSeleccionado } = carConfigR;
  
      
      if (
        modeloSeleccionado &&
        versionSeleccionada &&
        colorSeleccionado &&
        motorSeleccionado &&
        cambioSeleccionado &&
        mediosSeleccionado
      ) {
        obtenerProductos(
          modeloSeleccionado,
          versionSeleccionada,
          colorSeleccionado,
          [], 
          motorSeleccionado,
          cambioSeleccionado,
          mediosSeleccionado
        );
      }
    }
  }, [carConfigR]);


  useEffect(() => {
    if (carConfigR360) {
      const { modeloSeleccionado, versionSeleccionada, colorSeleccionado, equipamientoSeleccionado } = carConfigR360;
  
     
      if (
        modeloSeleccionado &&
        versionSeleccionada &&
        colorSeleccionado &&
        equipamientoSeleccionado
      ) {
        obtenerProductos(
          modeloSeleccionado,
          versionSeleccionada,
          colorSeleccionado,
          equipamientoSeleccionado,
          [], 
          [], 
          []  
        );
      }
    }
  }, [carConfigR360]);
  


  const obtenerProductos = async (modelo, version, color, equipx_id, motor, cambio, medio) => {
    //console.log('url productos', `https://kodeapp.onrender.com/api/productos?modelox_id=${modelo}&versionx_id=${version}&colorx_id=${color}&equipx_id=${equipx_id}&motorx_id=${motor}&cambiox_id=${cambio}&mediox_id=${medio}`)
    try {
      const response = await axios.get(`https://kodeapp.onrender.com/api/productos?modelox_id=${modelo}&versionx_id=${version}&colorx_id=${color}&equipx_id=${equipx_id}&motorx_id=${motor}&cambiox_id=${cambio}&mediox_id=${medio}`);
      //console.log('primer elemento del backend', response.data.productos[0].portada)
      if (response.data.productos && response.data.productos.length > 0) {
        setProductos(response.data.productos);
        const totalProductos = response.data.productos.length;
        setTotalPages(Math.ceil(totalProductos / productsPerPage));
      } else {
        console.error('La respuesta del backend no contiene productos válidos.');
      }
    } catch (error) {
      console.error('Error al obtener los productos:', error);
    }
  };



  return (
    <Box  sx={{ flexGrow: 1, textAlign: 'center' }}>
      <Grid container spacing={3}>
        {productos
          .slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)
          .map(producto => (
            <Grid key={producto.id} lg={3} xs={12} sm={6}>
              <CenteredBlogCardProduct2
                image={producto.portada[0]['img']}
                title={producto.modelo}
                description={producto.version}
                precio={producto.portada[0]['precio']}
                medio={producto.portada[0]['medio']}
                motor={producto.portada[0]['motor']}
                cambio={producto.portada[0]['cambio']}
                cuota={producto.portada[0]['cuota'].split("/")[0]}
                espec={producto.portada[0]['espec']}
                entrega={producto.portada[0]['entrega']}
                modelo2={producto.Modelo_2}
                action={producto.id}
                
              />
            </Grid>
          ))}
      </Grid>
      <Box sx={{ mt: 6, width: '100%' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          variant="contained"
          size="large"
          sx={{
            '& .Mui-selected': {
              backgroundColor: '#344767',
              color: '#fff',
            },
            display: 'inline-block',
          }}
        />
      </Box>
    </Box>
  );
}

export default Productos;
